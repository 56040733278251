/* Full-screen overlay */
.loader-overlay {
  position: fixed; /* Overlay on top of everything */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Dim background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Ensure it's above all other elements */
  pointer-events: none; /* Allow interactions with underlying components if needed */
}

/* Spinner loader */
.loader {
  width: 50px;
  height: 50px;
  border: 5px solid rgba(255, 255, 255, 0.6);
  border-top-color: #ffffff; /* Spinner color */
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

/* Spinner animation */
@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
