.card-container {
  perspective: 1000px;
}
.card {
  width: 100%;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  position: relative;
}
.card.rotated {
  transform: rotateY(180deg);
}
.card-face {
  backface-visibility: hidden;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.card-back {
  transform: rotateY(180deg);
  position: absolute;
}
