@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

body {
  font-family: "Poppins", sans-serif;
  font-weight: 200;
  font-style: normal;
  font-size: 15px;
}

/* Custom scrollbar styles */
.modal-scroll {
  max-height: 70vh; /* Adjust this as needed */
  overflow-y: auto;
}

.modal-scroll::-webkit-scrollbar {
  width: 8px; /* Set the width of the scrollbar */
}

.modal-scroll::-webkit-scrollbar-thumb {
  background-color: #888; /* Color of the scrollbar thumb */
  border-radius: 10px; /* Rounded corners */
}

.modal-scroll::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Darker thumb on hover */
}

.modal-scroll::-webkit-scrollbar-track {
  background: #f1f1f1; /* Color of the track */
}
