/* .navbar-nav .dropdown-menu {
  display: flex;
  flex-direction: row;
}

.navbar-nav .dropdown-menu .dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  display: block;
  flex-direction: column;
} */

/* Targeting the scrollbar */
::-webkit-scrollbar {
  width: 1px; /* Adjust this value to make the scrollbar thinner */
}

/* Styling the scrollbar track */
::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* Adjust the background color */
}

/* Styling the scrollbar thumb (the draggable part) */
::-webkit-scrollbar-thumb {
  background-color: #888; /* Color of the draggable part */
  border-radius: 10px; /* Optional: Adds rounded corners */
}

/* Hover effect for the scrollbar thumb */
::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Darker color on hover */
}
